import React from 'react'
import { graphql } from 'gatsby'
import { SwedishCookiesInfo } from '@accodeing/gatsby-theme-heimr'

import Layout from 'components/Layout'

const Cookies = ({ data }) => (
  <Layout
    pageTitle='Cookies'
    backgroundImage={data.imageBackground.childImageSharp.gatsbyImageData}
  >
    <oma-grid-row class='row row--with-top-margin'>
      <SwedishCookiesInfo />
    </oma-grid-row>
  </Layout>
)

export const query = graphql`
  {
    imageBackground: file(
      relativePath: { eq: "cookies-cream-serveret-pa-fat.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: FULL_WIDTH)
      }
    }
  }
`

export default Cookies
